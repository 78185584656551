<template>
	<div style="background: black">
		<indexPc class="about_pc"></indexPc>
		<div
			class="about_mobile about_view Main margin_auto"
			style="padding-bottom: 60px"
		>
			<div class="about_img">
				<img
					src="https://res.metaera.hk/resources/assets/images/aboutus/a18.png"
					alt=""
					class=""
				/>
			</div>
			<div class="about_content">
				<div class="about_content_left">
					<div class="about_content_text">
						<div>
							MetaEra 是 Web 3.0
							行业领先的资讯平台和品牌与增长专家。在全球各地区利用全方位优势资源，为您的品牌管理和业务增长提供创意型解决方案和定制化服务。
						</div>
					</div>
				</div>
				<div class="about_content_right">
					<div class="about_content_title">
						<span>联系我们</span>
					</div>
					<div class="about_content_box">
						<div class="about_content_box_left">
							<div class="about_content_box_title">WeChat</div>
							<div class="about_content_box_content">
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/a3_w1.png"
								/>
								<span>MetaEra_Media</span>
							</div>
							<div style="margin-top: 40px"></div>
							<div class="about_content_box_title">Telegram</div>

							<div class="about_content_box_content">
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/a15_w.png"
								/>
								<span>MetaEra_Media</span>
							</div>
							<div style="margin-top: 40px"></div>
							<div class="about_content_box_title">活动合作</div>
							<div
								class="about_content_box_content pointer"
								@click="sendEmail('event@metaera.hk')"
							>
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/a8_w.png"
								/>
								<span>Email: event@metaera.hk </span>
							</div>
							<div class="about_content_box_content">
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/a15_w.png"
								/>
								<span>Telegram: MetaEraEvent</span>
							</div>
						</div>
						<div class="about_content_box_right">
							<div class="about_content_box_title">商务合作</div>
							<div
								class="about_content_box_content"
								@click="sendEmail('sales@metaera.hk')"
							>
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/a8_w.png"
								/>
								<span>sales@metaera.hk</span>
							</div>

							<div style="margin-top: 40px"></div>
							<div class="about_content_box_title">市场合作</div>
							<div
								class="about_content_box_content"
								@click="sendEmail('marketing@metaera.hk')"
							>
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/a8_w.png"
								/>
								<span>marketing@metaera.hk</span>
							</div>

							<div style="margin-top: 40px"></div>
							<div class="about_content_box_title">内容推广</div>
							<div
								class="about_content_box_content"
								@click="sendEmail('content@metaera.hk')"
							>
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/a8_w.png"
								/>
								<span>content@metaera.hk</span>
							</div>

							<div style="margin-top: 40px"></div>
							<div class="about_content_box_title">HR</div>
							<div
								class="about_content_box_content"
								@click="sendEmail('hr@metaera.hk')"
							>
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/a8_w.png"
								/>
								<span>hr@metaera.hk</span>
							</div>
						</div>
					</div>
					<div class="about_content_bottom">
						<div class="about_content_box_title">MetaEra社區：</div>
						<div class="about_content_bottom_1">
							<div
								class="about_content_bottom_1_item pointer"
								@click="showUrl('https://twitter.com/MetaEraHK')"
							>
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/a5.png"
								/>
								<span>Twitter</span>
							</div>
							<div
								class="about_content_bottom_1_item pointer"
								@click="showUrl('https://twitter.com/MetaEraCN')"
							>
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/a5.png"
								/>
								<span>Twitter中文</span>
							</div>
							<div
								class="about_content_bottom_1_item pointer"
								@click="showUrl('https://t.me/MetaEraHK')"
							>
								<img
									src="https://res.metaera.hk/resources/assets/images/aboutus/a12.png"
								/>
								<span>Telegram</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="about_bottom">
				<div class="about_content_title">
					<img
						src="https://res.metaera.hk/resources/assets/images/aboutus/a9.png"
					/>
					<span>Logo下载</span>
				</div>
				<div class="about_bottom_1 about_bottom_1_pc">
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/ME-logo.svg')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a11.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/ME-logo.ai')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a6.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/ME-logo.eps')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a7.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/ME-logo-黑色.png')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a10.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/ME-logo-反白.png')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a10.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo(白)</span>
					</div>
				</div>

				<div class="about_bottom_1 about_bottom_1_mobile">
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/ME-logo.svg')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a11.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/ME-logo.ai')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a6.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
				</div>

				<div class="about_bottom_1 about_bottom_1_mobile">
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/ME-logo.eps')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a7.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/ME-logo-黑色.png')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a10.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
				</div>

				<div class="about_bottom_1 about_bottom_1_mobile">
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/ME-logo-反白.png')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a10.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://res.metaera.hk/resources/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo(白)</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import indexPc from "./index_pc.vue";
export default {
	name: "",
	components: { indexPc },
	methods: {
		downloadFile(fileName) {
			const fileUrl = "/path/to/" + fileName; // 文件的URL地址
			const link = document.createElement("a");
			link.href = fileName;
			link.setAttribute("download", fileName);
			link.click();
		},
		sendEmail(url) {
			const email = url;
			const subject = "";
			const body = "";
			window.location.href = `mailto:${email}?subject=${encodeURIComponent(
				subject
			)}&body=${encodeURIComponent(body)}`;
		},
		showUrl(url) {
			window.open(url, "");
		},
	},
};
</script>
<style scoped>
.about_pc {
	display: block;
}
.about_mobile {
	display: none;
	color: #fff;
}
.about_mobile span {
	color: #fff !important;
}
.about_view {
	width: 80%;
	background: rgba(0, 0, 0, 0);
}
.about_img {
	padding-top: 0px;
	width: 100%;
	height: auto;
}
.about_img img {
	width: 100%;
	height: auto;
	border-radius: 10px;
}

.about_content {
	margin-top: 64px;
	display: flex;
	justify-content: space-between;
}

.about_content_left,
.about_content_right {
	width: 45%;
}
.about_content_right {
	margin-top: 0px;
}
.about_content_title {
	display: flex;
}
.about_content_title img {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	opacity: 1;

	/* 333 */
	background: #333333;
}
.about_content_title span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 16px;
	text-align: center;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.about_content_text {
	margin-top: 30px;

	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #fff;
	display: block;
}
.about_content_text div {
	margin-top: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 14 / 1280), 16px);
	font-weight: normal;
	line-height: 30px;
}
.about_content_text p {
	margin-top: 10px;
	width: 50%;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 14 / 1280), 16px);
	font-weight: normal;
	line-height: 22px;
}
.about_content_text_div {
	display: flex;
}
.about_content_text_p {
	margin-left: 00px;
}

.about_content_slogn {
	margin-top: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 26px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #fff;
}

.about_content_box {
	margin-top: 30px;
	display: block;
	justify-content: space-between;
}

.about_content_box_right {
	margin-top: 40px;
}

.about_content_box_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 14px;
	text-align: center;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #fff;
}
.about_content_box_content {
	display: flex;
	margin-top: 25px;
}

.about_content_box_content img {
	width: 16px;
	height: 16px;
	opacity: 1;
}

.about_content_box_content span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.about_content_bottom_1 {
	display: flex;
	margin-top: 30px;
	justify-content: space-between;
}
.about_content_bottom_1_item {
	display: flex;
}
.about_content_bottom_1_item img {
	width: 20px;
	height: 20px;
	border-radius: 2px;
	opacity: 1;

	/* 333 */
	background: #333333;
}

.about_content_bottom_1_item span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}
.about_content_bottom {
	margin-top: 50px;
}
.about_bottom {
	margin-top: 80px;
	margin-bottom: 100px;
}
.about_bottom_1 {
	margin-top: 35px;
	display: flex;
	justify-content: space-between;
}

.about_bottom_1_item {
	display: flex;
	align-items: center;
}

.about_bottom_1_item span {
	margin-left: 6px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.about_bottom_1_item_img1 {
	width: 32px;
	height: 32px;
	opacity: 1;
}

.about_bottom_1_item_img2 {
	margin-left: 15px;
	width: 12px;
	height: 12px;
	opacity: 1;
}
.about_bottom_1_mobile {
	display: none;
}
.about_bottom_1_pc {
	display: flex;
}
</style>
<style scoped>
@media screen and (max-width: 900px) {
	.about_pc {
		display: none;
	}
	.about_mobile {
		display: block;
	}
	.about_bottom {
		display: none;
	}
	.about_content_text {
		margin-top: 10px;
	}
	.about_content_bottom {
		margin-top: 40px;
	}
	.about_img {
		padding-top: 20px;
		width: 100%;
		height: auto;
	}
	.about_view {
		width: 80%;
		background: rgba(0, 0, 0, 0);
	}
	.about_content {
		margin-top: 10px;
		display: block;
	}

	.about_content_left,
	.about_content_right {
		width: 100%;
	}

	.about_content_right {
		margin-top: 20px;
	}

	.about_bottom_1_mobile {
		display: flex;
	}
	.about_bottom_1_pc {
		display: none;
	}
	.about_content_text div {
		margin-top: 10px;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-size: clamp(10px, calc(100vw * 14 / 392), 16px);
		font-weight: normal;
		line-height: 30px;
	}
	.about_content_text p {
		margin-top: 10px;
		width: 50%;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-size: clamp(10px, calc(100vw * 14 / 392), 16px);
		font-weight: normal;
		line-height: 22px;
	}
}
</style>
